import { createSlice, dynamicPage } from '@loopbound-ares/microfrontend';

let HomePage = dynamicPage(async () => (await import('./home')).HomePage);
let Layout = dynamicPage(async () => (await import('./layout')).ProductLayout);

export let productSlice = createSlice([
  {
    path: ':instanceId',
    element: <Layout />,

    children: [
      {
        path: '',
        element: <HomePage />
      }
    ]
  }
]);
