import { createSlice, dynamicPage } from '@loopbound-ares/microfrontend';
import { LargePaneLayout } from '@loopbound-ares/scenes';
import { Outlet } from 'react-router-dom';

let HomePage = dynamicPage(() => import('@loopbound-ares/scenes').then(m => m.HomePage));

export let selectorSlice = createSlice(
  [
    {
      path: '',
      element: (
        <LargePaneLayout>
          <Outlet />
        </LargePaneLayout>
      ),
      children: [
        {
          path: '',
          element: <HomePage />
        }
      ]
    }
  ],
  {}
);
