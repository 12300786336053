export let config = {
  urls: {
    landing: 'https://loopbound.com',
    dashboard: import.meta.env.VITE_INTEGRATIONS_FRONTEND_URL,
    rpc: import.meta.env.VITE_INTEGRATIONS_API_URL
  },

  product: {
    id: 'integrations',
    name: 'Integrations'
  }
};
